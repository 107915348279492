<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n8">
                <v-col>
                    <tile-search-date
                        init-type="WEEK"
                        @changed="searchDateChanged"
                        @inited="searchDateInited"
                        ref="date_range_select"
                        :from-day="searchParam.search_start"
                        :to-day="searchParam.search_end"
                    ></tile-search-date>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">
                            mdi-magnify
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="text-right mt-n5" style="font-size:20px; color:#555555;">통화량 : {{ this.$options.filters.number(totalCount) }} 건</v-col>
            </v-row>
            <v-card flat class="mt-4">
                <v-simple-table class="border-table">
                    <template v-slot:default>
                        <thead>
                        <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                            <th class="text-center text-h6">
                                접수일시
                            </th>
                            <th class="text-center text-h6">
                                처리일시
                            </th>
                            <th class="text-center text-h6">
                                접수경로
                            </th>
                            <th class="text-center text-h6">
                                응대콜리
                            </th>
                            <th class="text-center text-h6">
                                처리상태
                            </th>
                        </tr>
                        </thead>
                        <tbody style="color:#555555;">
                        <template v-for="(statItem, statIdx) in statList">
                            <tr style="height:68px;" :key="statIdx">
                                <td class="text-center" style="font-size:20px;">{{ statItem.reg_dt | dateMin }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.answer_dt | dateMin }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.menu_name }}</td>
                                <td class="text-center" style="font-size:20px;">{{ statItem.answerer_name }}</td>
                                <td class="text-center" style="font-size:20px;">{{ answerText(statItem.answer_yn) }}</td>
                            </tr>
                        </template>
                        <tr style="height:69px;" v-if="!statList || statList.length == 0">
                            <td class="text-center" style="font-size:20px;" colspan="5">조회된 결과가 없습니다</td>
                        </tr>

                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            <v-row class="mt-4">
                <v-col cols="12" class="text-right">
                    <tile-paging :pageNo="searchParam.page_no" :totalPageCount="totalPageCount" @goPage="doSearch"></tile-paging>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapState} from 'vuex'

export default {
    name: 'svc_hist_callback_list',
    components: {
        'TilePaging': () => import('@/components/common/tile_paging.vue'),
        'TileSearchDate': () => import('@/components/common/tile_search_date.vue'),
    },
    data: () => ({
        searchParam: {
            search_start: '',
            search_end: '',
            page_no: 1,
            page_size: 25
        },
        initFlags: {'search_date': false},

    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_hist_callback')

        let lastMenuId = this.$store.getters['lastMenuId']
        if (lastMenuId == 'svc_hist_callback' && this.curSearchParam) {
            Object.assign(this.search, this.curSearchParam)
        }
    },
    computed: {
        ...mapState('svc_hist_callback', {
            curSearchParam: state => state.searchParam,
            totalCount: state => state.totalCount,
            statList: state => state.statList,
        }),
        totalPageCount() {
            if (this.totalCount == 0) {
                return 0
            }
            var pageSize = (this.searchParam && this.searchParam.page_size) ? this.searchParam.page_size : 25
            var cnt = Math.floor(this.totalCount / pageSize) + (((this.totalCount % pageSize) > 0) ? 1 : 0)
            return cnt
        },
        isLoaded() {
            if (this.statList) {
                return true
            }
            return false
        }
    },
    methods: {
        searchDateInited(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
            this.initFlags['search_date'] = true
            this.doSearch()
        },
        searchDateChanged(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
        },
        doSearch(no) {
            if (no) {
                this.searchParam.page_no = no
            }
            if (!this.initFlags.search_date) {
                return
            }

            this.$store.dispatch('svc_hist_callback/getStatList', {
                searchParam: this.searchParam,
                scb: (stat_list, total_count, va) => {
                },
                fcb: (err, va) => {
                },
                va: this
            })
        },
        answerText(c) {
            if (c == 'Y') {
                return '처리완료'
            }
            return '접수'
        }
    }
}
</script>